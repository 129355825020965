var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['panel', 'prog-join',
			_vm.isHeaderIndent? 'mt-0 pt-1' : '',
			_vm.isFooterIndent? 'mb-0 pb-1' : '',
			_vm.block.appearance? _vm.block.appearance : ''
		],style:(_vm.block.background_image_default ?
			'background-image:url(' + _vm.block.background_image_default + ')' : '')},[_c('div',{staticClass:"container"},[(_vm.isHeaderVisible)?_c('div',{class:[
					'panel_header',
					_vm.isBrand? 'sk' : '',
				]},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col-12"},[(_vm.block.block_title === null)?_c('h2',[_vm._v("\n\t\t\t\t\t\t\tКак поступить\n\t\t\t\t\t\t")]):_c('h2',[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.block.block_title)+"\n\t\t\t\t\t\t")])])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"join_block"},[_c('ul',{staticClass:"join_list row justify-content-center"},_vm._l((_vm.block.items),function(item,index){return _c('li',{key:index,staticClass:"col-12 col-md-6 col-lg-3"},[_c('div',{staticClass:"join_item icon-0"},[_c('div',{staticClass:"step-block"},[_c('p',{class:'icon ' +item.icon}),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('step'))+" "+_vm._s(index + 1))])]),_vm._v(" "),_c('div',{staticClass:"title"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(item.title)+"\n\t\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(item.description)}}),_vm._v(" "),(index === 0)?_c('div',[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#empty-registration'),expression:"'#empty-registration'"}],staticClass:"btn btn-primary",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t('apply')))])]):_vm._e()])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }