<template>
	<div
		:class="['panel', 'prog-join',
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
			block.appearance? block.appearance : ''
		]"
		:style="block.background_image_default ?
			'background-image:url(' + block.background_image_default + ')' : ''"
	>
		<div class="container">
			<div
				v-if="isHeaderVisible"
				:class="[
					'panel_header',
					isBrand? 'sk' : '',
				]"
			>
				<div class="row align-items-end">
					<div class="col-12">
						<h2 v-if="block.block_title === null">
							Как поступить
						</h2>
						<h2 v-else>
							{{ block.block_title }}
						</h2>
					</div>
				</div>
				<!-- <nuxt-link class="panel_control" to="#"></nuxt-link> -->
			</div>
			<!-- <div class="themes_block">
                  <div v-html="this.content.html.ru"></div>
			</div>-->
			<div class="join_block">
				<ul class="join_list row justify-content-center">
					<li
						v-for="(item, index) in block.items"
						:key="index"
						class="col-12 col-md-6 col-lg-3"
					>
						<div class="join_item icon-0">
							<div class="step-block">
								<p :class="'icon ' +item.icon" />
								<p>{{ $t('step') }} {{ index + 1 }}</p>
							</div>
							<div class="title">
								{{ item.title }}
							</div>
							<div class="description" v-html="item.description" />
							<!--
              <div class="mb-3" v-if="index === 0">
                <a href="#" class="btn btn-secondary">Смотреть пример заявки</a>
							</div>-->
							<div v-if="index === 0">
								<a v-scroll-to="'#empty-registration'" href="#" class="btn btn-primary">{{ $t('apply') }}</a>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<!-- <div class="panel_footer">
                          <div class="row">
                              <div class="col-12">
                                  <nuxt-link
                                      to="#empty-registration"
                                      class="btn btn-primary"
                                  >{{ $t('register') }}</nuxt-link>
                              </div>
                          </div>
			</div>-->
		</div>
	</div>
</template>

<script>
// TODO: background_image_default intersection observer
import panel from '~/mixins/panel';

export default {
	name: 'ProgJoin',
	mixins: [panel],
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/panel-program-join";
</style>
